import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function importAll(r) {
  return r.keys().map(r);
}

function Documentation() {
  const [documentation, setDocumentation] = useState([]);
  const [expandedLanguages, setExpandedLanguages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const docs = importAll(require.context('../documentation', false, /\.json$/));
      const languageInfos = docs.map(doc => doc.language_info);
      setDocumentation(languageInfos);
    } catch (err) {
      console.error('Ошибка при импорте документации:', err);
      setError('Не удалось загрузить документацию.');
    } finally {
      setLoading(false);
    }
  }, []);

  const toggleLanguage = (languageName) => {
    setExpandedLanguages(prevState => ({
      ...prevState,
      [languageName]: !prevState[languageName],
    }));
  };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div style={styles.spinner}></div>
        <p style={styles.loadingText}>Загрузка документации...</p>
      </div>
    );
  }

  if (error) {
    return <p style={styles.error}>{error}</p>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Документация</h1>
      {documentation.map((lang, index) => (
        <div key={index} style={styles.languageSection}>
          <div style={styles.languageHeader}>
            <h2 style={styles.languageName}>{lang.name}</h2>
            <button
              onClick={() => toggleLanguage(lang.name)}
              style={styles.expandButton}
            >
              {expandedLanguages[lang.name] ? 'Свернуть' : 'Развернуть'}
            </button>
          </div>
          {expandedLanguages[lang.name] && (
            <div style={styles.tableContainer}>
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.th}>Компетенция</th>
                    <th style={styles.th}>0 - 2 балла</th>
                    <th style={styles.th}>3 - 5 баллов</th>
                    <th style={styles.th}>6 - 8 баллов</th>
                    <th style={styles.th}>9 - 10 баллов</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(lang.properties).map(([competency, skills], idx) => (
                    <tr key={idx}>
                      <td style={styles.td}>{competency}</td>
                      <td style={styles.td}>{skills.junior_skills}</td>
                      <td style={styles.td}>{skills.middle_skills}</td>
                      <td style={styles.td}>{skills.senior_skills}</td>
                      <td style={styles.td}>{skills.highest_skills}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1000px',
    margin: '0 auto',
    backgroundColor: 'rgba(255, 255, 255)',
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0,0,0,0.3)',
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    color: '#333',
  },
  languageSection: {
    marginBottom: '40px',
  },
  languageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '6px',
  },
  languageName: {
    margin: 0,
  },
  expandButton: {
    padding: '6px 12px',
    backgroundColor: '#fff',
    color: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  tableContainer: {
    overflowX: 'auto',
    marginTop: '15px',
  },
  table: {
    width: '100%',
    minWidth: '600px',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #ddd',
    padding: '12px',
    backgroundColor: '#f2f2f2',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh',
  },
  spinner: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #007bff',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 2s linear infinite',
  },
  loadingText: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#555',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginTop: '50px',
  },
};

const styleSheet = document.styleSheets[0];
const keyframes =
  `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default Documentation;
