import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Documentation from './Documentation';
import DevelopersList from './DevelopersList';

function CompetencyMatrix() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('matrices');

  const detailedAnalysis = location.state?.detailedAnalysis;

  if (!detailedAnalysis && activeTab === 'matrices') {
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>Матрица Компетенций</h1>
        <p style={styles.message}>
          Данные о компетенциях отсутствуют. Пожалуйста,{' '}
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              navigate('/');
            }}
            style={styles.link}
          >
            заполните форму анализа
          </a>.
        </p>
        {/* Кнопка "Назад" */}
        <button
          onClick={() => navigate('/')}
          style={styles.backButton}
        >
          Назад
        </button>
      </div>
    );
  }

  return (
    <div style={styles.pageContainer}>
      {/* Переключатель вкладок */}
      <div style={styles.tabContainer}>
        <button
          onClick={() => setActiveTab('matrices')}
          style={{
            ...styles.tabButton,
            backgroundColor: activeTab === 'matrices' ? '#007bff' : '#f2f2f2',
            color: activeTab === 'matrices' ? '#fff' : '#333',
          }}
        >
          Матрицы
        </button>
        <button
          onClick={() => setActiveTab('documentation')}
          style={{
            ...styles.tabButton,
            backgroundColor: activeTab === 'documentation' ? '#007bff' : '#f2f2f2',
            color: activeTab === 'documentation' ? '#fff' : '#333',
          }}
        >
          Документация
        </button>
        <button
          onClick={() => setActiveTab('developers')}
          style={{
            ...styles.tabButton,
            backgroundColor: activeTab === 'developers' ? '#007bff' : '#f2f2f2',
            color: activeTab === 'developers' ? '#fff' : '#333',
          }}
        >
          Список разработчиков
        </button>
      </div>

      {/* Содержимое в зависимости от активной вкладки */}
      {activeTab === 'matrices' ? (
        detailedAnalysis ? (
          <div style={styles.matricesContainer}>
            <h1 style={styles.title}>Компетенции Разработчика</h1>
            <div style={styles.developerInfo}>
              <p>
                <strong>Разработчик:</strong> {detailedAnalysis.developerName}
              </p>
              <p>
                <strong>Количество проанализированных файлов:</strong> {detailedAnalysis.totalFiles}
              </p>
            </div>

            {Object.entries(detailedAnalysis.languagesAnalyse).map(([languageName, languageData], index) => (
              <div key={index} style={styles.languageContainer}>
                <h2 style={styles.languageTitle}>{languageName}</h2>
                <div style={styles.tableContainer}>
                  <table style={styles.table}>
                    <thead>
                      <tr>
                        <th style={styles.th}>Компетенция</th>
                        <th style={styles.th}>Оценка</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(languageData.languageCompetencies).map(([competency, rating], idx) => (
                        <tr key={idx}>
                          <td style={styles.td}>{competency}</td>
                          <td style={styles.td}>{rating}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={styles.skillsContainer}>
                  <p>
                    <strong>Algorithm Skills:</strong> {languageData.algorithmSkills}
                  </p>
                  <p>
                    <strong>DB Skills:</strong> {languageData.dbSkills}
                  </p>
                  <p>
                    <strong>Broker Skills:</strong> {languageData.brokerSkills}
                  </p>
                </div>
                <div style={styles.summaryBox}>
                  <p>
                    <strong>Summary:</strong> {languageData.summary}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p style={styles.message}>Пожалуйста, заполните форму анализа.</p>
        )
      ) : activeTab === 'documentation' ? (
        <Documentation />
      ) : activeTab === 'developers' ? (
        <DevelopersList />
      ) : null}

      {/* Кнопка "Назад" */}
      <button
        onClick={() => navigate('/')}
        style={styles.backButton}
      >
        Назад
      </button>
    </div>
  );
}

const styles = {
  pageContainer: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    backgroundColor: 'rgba(255, 255, 255)',
    borderRadius: '10px',
    boxShadow: '0 0 15px rgba(0,0,0,0.3)',
    position: 'relative',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    gap: '10px',
  },
  tabButton: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  matricesContainer: {
  },
  developerInfo: {
    marginBottom: '30px',
    color: '#333',
  },
  languageContainer: {
    marginBottom: '40px',
    color: '#333',
  },
  languageTitle: {
    marginBottom: '10px',
    color: '#007bff',
  },
  tableContainer: {
    overflowX: 'auto',
    marginBottom: '15px',
  },
  table: {
    width: '100%',
    minWidth: '400px',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #ddd',
    padding: '12px',
    backgroundColor: '#f2f2f2',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'center',
  },
  skillsContainer: {
    marginTop: '10px',
    color: '#555',
  },
  summaryBox: {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    borderLeft: '4px solid #007bff',
    borderRadius: '4px',
  },
  backButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    padding: '12px 20px',
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    zIndex: '1000',
  },
  message: {
    textAlign: 'center',
    marginTop: '50px',
    fontSize: '18px',
    color: '#555',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

const styleSheet = document.styleSheets[0];
const keyframes =
  `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default CompetencyMatrix;
